import Hero from "../../components/Hero";
import About from "../../components/AboutMe";
import BerlinInfo from "../../components/BerlinInfo";
import PageHeader from "../../components/PageHeader";

const Landing = ({ name }) => {
  const styles = {
    landing: {
      height: "calc(100% - 93px)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    sectionTitle: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "var(--spacing)",
      overflow: "hidden",
    }
  };

  return (
    <>
      <section className="landing container" style={styles.landing}>
        <Hero name={name} />
      </section>
      <section style={styles.sectionTitle}>
        <PageHeader title="Berlin" description="Classes in" />
        <BerlinInfo />
        <About />
      </section>

    </>
  );
};

export default Landing;
