import PageHeader from "../../components/PageHeader";
import OnlineForm from "../../components/OnlineForm";
import { motion } from "framer-motion";

const Online = ({ name, email, location }) => {
  return (
    <>
      <section className="contact container">
        <PageHeader title="Forró Community Online" description="Pre-register Now" />
        <div className="container">
          <div className="col-12 col-lg-12">
            <OnlineForm />
          </div>
          <div className="col-12 col-lg-12" style={{"align-items": "center"}}>
            <motion.h4 className="contentTitle">What is waiting for you, anytime in your pocket:</motion.h4>
            <motion.p className="contentTitle">
              <b>- Classrooms:</b> 
              <spam style={{"color":"#999999"}}> Starting with 5 classes per level, to be extended regularly according to the wishes of the group;</spam>
            </motion.p>
            <motion.p className="contentTitle">
              <b>- Q&A:</b> 
              <spam style={{"color":"#999999"}}> Ask dance questions (about the lessons or other topics) and I will answer to the best of my knowledge;</spam>
            </motion.p>
            <motion.p className="contentTitle">
              <b>- Channels:</b> 
              <spam style={{"color":"#999999"}}> Public discussions with your dance fellows, announcements about our group and external forró communities;</spam>
            </motion.p>
            <motion.p className="contentTitle">
              <b>- Chats:</b> 
              <spam style={{"color":"#999999"}}> Private chats with your dance fellows.</spam>
            </motion.p>
            <br />

            <motion.h4 className="contentTitle">In addition:</motion.h4>
            <motion.p className="contentTitle" style={{"color":"#999999"}}>- Join until <spam style={{"color":"red"}}>May 15</spam> members and get a <spam style={{"color":"#FFFFFF"}}>30min private session with me.</spam></motion.p>
            <motion.p className="contentTitle" style={{"color":"#999999"}}>- Join in May/2024 and get <spam style={{"color":"#FFFFFF"}}>feedback to up to 4 dancing videos</spam> of yours, if you wish.</motion.p>
            <motion.p className="contentTitle" style={{"color":"#999999"}}>- More lessons, features and benefits to be added soon for everybody.</motion.p>
            <br />

            <motion.h4 className="contentTitle">Pricing:</motion.h4>
            <motion.p className="contentTitle">
              {/* <b>Q&A:</b>  */}
              <p style={{"color":"#999999"}}>- The current price for the monthly membership is 29 USD (currently ~27€)</p>
              <p style={{"font-size":12}}><i>*Note: <spam style={{"color":"#999999"}}>a 30min private class alone would be more expensive than that. So don't miss out on the extra benefits,</spam> <b>join NOW!</b></i></p>

            </motion.p>
            <br />
          </div>
        </div>
      </section>
    </>
  );
};

export default Online;
