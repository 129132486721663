import aboutMeImg from "../images/aboutme.jpeg";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { NavLink } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";

const AboutMe = ({ name }) => {
  const [ref, inView] = useInView({
    threshold: 0.4,
    triggerOnce: true,
  });

  const staggerVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const paragraphVariants = {
    initial: { y: 20, opacity: 0 },
    animate: { y: 0, opacity: 1 },
  };

  return (
    <section className="about">
      <div className="aboutContainer container">
        <div className="row">
          <motion.div
            className="personalImage col-12 col-lg-6"
            ref={ref}
            initial={{ x: "-10vw", opacity: 0, scale: 0.5 }}
            animate={inView ? { x: 0, opacity: 1, scale: 1 } : { x: "-10vw", opacity: 0, scale: 0.5 }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
            whileHover={{ scale: 1.05 }}
          >
            <motion.img src={aboutMeImg} alt={name} />
          </motion.div>
          <div className="col-12 col-lg-6">
            <motion.div className="contentContainer" variants={staggerVariants}>
              <motion.h4 variants={paragraphVariants}>Nice to meet you! 👋🏻</motion.h4>
              <motion.h5 variants={paragraphVariants}>I'm Matheus, dance educator & forró teacher =)</motion.h5>
              <motion.div
                className="contentDescription"
                variants={staggerVariants}
                initial="initial"
                animate={inView ? "animate" : "initial"}
              >
                <motion.p>
                  Born in Brazil, living in Germany since 2018, I've already tought forró in <span style={{ color: "white" }}>over 10 countries</span>. 
                  And even though I still travel to give workshops on festivals, now my focus is <span style={{ color: "white" }}>building a community in Berlin</span>.
                </motion.p>
                <br />
                <motion.p>
                  I've learned many styles of forró (like <span style={{ color: "white" }}>universitário & roots</span>) along many years at <span style={{ color: "white" }}>4 different schools</span> in Brazil and Germany.
                  Besides the dance technique, I also completed many <span style={{ color: "white" }}>courses for teaching by top Ball Room schools/teachers</span> from Brazil (Cardas, Mimulus, 8Tempos, etc.).
                  All to give you the best learning experience!
                </motion.p>
                <br />
                <motion.p>
                  Some of the topics I love are:
                  <ul style={{"list-style-type": "circle", "list-style-position": "inside", "margin-left": "10px"}}>
                    <li> <span style={{ color: "white" }}>Connection:</span> mind your partner so everybody enjoys the dance</li>
                    <li> <span style={{ color: "white" }}>Musicality:</span> represent the sounds of the music with body movement</li>
                    <li> <span style={{ color: "white" }}>Qualities of movement:</span> it's not only about what movement to do, but how.</li> 
                  </ul>
                </motion.p>
              </motion.div>
              <NavLink to="/portfolio">
                <motion.button className="btn" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.99 }}>
                  <p>Find out more</p>
                  <div>
                    <FiArrowUpRight whileHover={{ scale: 4 }} className="arrow-icon" />
                  </div>
                </motion.button>
              </NavLink>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
