import SocialIcons from "../components/SocialIcons";
const Footer = () => {
  return (
    <footer>
      <SocialIcons />
      Forró with Matheus
    </footer>
  );
};

export default Footer;
